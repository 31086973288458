import React from 'react'
import profile from './profile_two.png'

import './Home.css'

const SPEED_FRAMES = 3;
const BOX_WIDTH = 15;
const BOX_HEIGHT = 30;
const DEGRADE_FACTOR = 0.0005;

class Home extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      frames: [],
      triangles: {},
      cursor: [-10000, -10000],
      fps: 0,
    };
    this.canvas = React.createRef();
  }

  draw() {
    this.updateFrames();

    this.updateTriangles();
    if (this.state.cursor[0] &&
        this.state.cursor[0] > 0 &&
        this.state.cursor[1] &&
        this.state.cursor[1] > 0) {
      const triangles = Object.assign({}, this.state.triangles);
      let t = this.getCurrentTriangle();
      let tIndex = `t${t[0][0]}${t[0][1]}${t[1][0]}${t[1][1]}${t[2][0]}${t[2][1]}`;
      triangles[tIndex] = {
        'points': t,
        'alpha': .7,
      }
      this.setState({triangles: triangles});
    }

    const context = this.canvas.current.getContext('2d');
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);
    context.fillStyle = '#000000';
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);
    this.drawTriangles();

    window.requestAnimationFrame(this.draw.bind(this));
  }

  updateFrames() {
    const now = performance.now();
    const frames = this.state.frames.slice();
    while (frames.length > 0 && frames[0].time <= now - 1000) {
      frames.shift();
    }
    frames.push({
      time: now,
      cursorX: this.state.cursor[0],
      cursorY: this.state.cursor[1]
    });
    this.setState({
      frames: frames,
      fps: frames.length,
    });
  }

  updateTriangles() {
    const triangles = Object.assign({}, this.state.triangles);
    for (const triangle in triangles) {
      const t = triangles[triangle];
      t['alpha'] -= DEGRADE_FACTOR;
    }
    this.setState({triangles: triangles})
  }

  drawTriangles() {
    const context = this.canvas.current.getContext('2d');
    for (const triangle in this.state.triangles) {
      const t = this.state.triangles[triangle];
      context.fillStyle = `rgb(255, 255, 255, ${t['alpha']})`;
      context.beginPath();
      context.moveTo(t['points'][0][0], t['points'][0][1]);
      context.lineTo(t['points'][1][0], t['points'][1][1]);
      context.lineTo(t['points'][2][0], t['points'][2][1]);
      context.fill();
    }
  }

  getCurrentTriangle() {
    let cursorX, cursorY;
    if (this.canvas.current.width > 600) {
      cursorX = this.state.cursor[0];
      cursorY = this.state.cursor[1];
    } else {
      cursorX = Math.floor(Math.random() * this.canvas.current.width);
      cursorY = Math.floor(Math.random() * this.canvas.current.height);
    }

    const rectX = BOX_WIDTH * Math.floor(cursorX / BOX_WIDTH);
    const rectY = BOX_HEIGHT * Math.floor(cursorY / BOX_HEIGHT);

    const rectRelX = cursorX - rectX;
    const rectRelY = cursorY - rectY;

    // Paint an upwards-sloping primary triangle when offsets match, so that
    // triangles paint a diamond pattern.
    let upTri = (((rectX / BOX_WIDTH) % 2) === ((rectY / BOX_HEIGHT) % 2));

    if (upTri) {
      if (rectRelY < BOX_HEIGHT - ((BOX_HEIGHT / BOX_WIDTH) * rectRelX)) {
        return [
          [rectX, rectY],
          [rectX, rectY + BOX_HEIGHT],
          [rectX + BOX_WIDTH, rectY]
        ];
      } else {
        return [
          [rectX, rectY + BOX_HEIGHT],
          [rectX + BOX_WIDTH, rectY + BOX_HEIGHT],
          [rectX + BOX_WIDTH, rectY]
        ];
      }
    } else {
      if (rectRelY > ((BOX_HEIGHT / BOX_WIDTH) * rectRelX)) {
        return [
          [rectX + BOX_WIDTH, rectY + BOX_HEIGHT],
          [rectX, rectY + BOX_HEIGHT],
          [rectX, rectY]
        ];
      } else {
        return [
          [rectX, rectY],
          [rectX + BOX_WIDTH, rectY],
          [rectX + BOX_WIDTH, rectY + BOX_HEIGHT]
        ];
      }
    }
  }

  getSpeed() {
    if (this.state.frames.length < SPEED_FRAMES) {
      return 0;
    }
    const first = this.state.frames[this.state.frames.length - SPEED_FRAMES - 1];
    const last = this.state.frames[this.state.frames.length - 1];
    if (first.cursorX < 0 || first.cursorY < 0 ||
        last.cursorX < 0 || last.cursorY < 0) {
      return 0;
    }
    const a = last.cursorX - first.cursorX;
    const b = last.cursorY - first.cursorY;
    const dist = Math.hypot(a, b);
    const time = (last.time - first.time) / 1000;
    const pixPerSec = (Math.round(dist / time) > 1000 ? 1000 : Math.round(dist / time));
    if (pixPerSec === 0) {
      return 0;
    } else {
      return Math.log10(pixPerSec) / 3;
    }
  }

  handleResize() {
    this.canvas.current.width = document.body.clientWidth;
    this.canvas.current.height = document.body.clientHeight;
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize.bind(this));
    window.addEventListener('mousemove', e => {
      this.setState({cursor: [e.x, e.y]});
    });
    window.requestAnimationFrame(this.draw.bind(this));
  }

  render() {
    return (
      <div className="Home">
        <canvas ref={this.canvas}></canvas>
        <div id="overlay">
          <div id="profile-header">
              <div id="profile-photo"><img alt="alex mineer portrait" src={profile} /></div>
              <div id="profile-greeting">Hi, I'm Alex.</div>
          </div>
          <div id="profile-content">
              You've reached the home page of Alex Mineer. One day, I may add more
              content here; until then, about me. I spend my time...
              <ul>
              <li>... hanging out with my wonderful & talented <a target="_blank" rel="noreferrer" href="https://jennifermineer.com">spouse</a>.</li>
              <li>... squashing bugs & distributing bits for <a target="_blank" rel="noreferrer" href="https://www.google.com/chrome/index.html">Chrome</a> @ Google.</li>
              <li>... building <a target="_blank" rel="noreferrer" href="https://chromiumdash.appspot.com">web apps</a>.</li>
              <li>... skiing, hiking, cooking, playing games, taking pictures, & other stuff.</li>
              </ul>
          </div>
          <div id="profile-footer">
              Contact: <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/alex-mineer/">LinkedIn</a>
          </div>  
        </div>
      </div>
    )
  }
}

export default Home;
