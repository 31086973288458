import React from 'react'
import Home from './Home.js';

import './App.css';

function App() {

  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
